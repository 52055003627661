import Flatpickr from 'stimulus-flatpickr'

export default class extends Flatpickr {
  initialize () {
    this.config = {
      mode: 'range',
      altInput: true,
      altFormat: 'm/d/Y',
      dateFormat: 'Y-m-d'
    }
  }

  connect () {
    this.config = {
      ...this.config,
      defaultDate: [this.data.get('startDate'), this.data.get('endDate')]
    }

    super.connect()
  }

  change (selectedDates, dateStr, instance) {
    document.querySelector(
      this.data.get('startDateElement')
    ).value = instance.formatDate(selectedDates[0], 'Y-m-d')

    document.querySelector(
      this.data.get('endDateElement')
    ).value = instance.formatDate(selectedDates[1], 'Y-m-d')
  }
}
