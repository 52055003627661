import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['content', 'keyword', 'iconDown', 'iconUp']

  toggle () {
    if (this.contentTarget.classList.contains('hidden')) {
      this.contentTarget.classList.remove('hidden')

      if (this.hasKeywordTarget) {
        this.keywordTarget.innerHTML = 'hide'
      }

      if (this.hasIconDownTarget) {
        this.iconDownTarget.classList.add('hidden')
      }

      if (this.hasIconUpTarget) {
        this.iconUpTarget.classList.remove('hidden')
      }
    } else {
      this.contentTarget.classList.add('hidden')

      if (this.hasKeywordTarget) {
        this.keywordTarget.innerHTML = 'show'
      }

      if (this.hasIconDownTarget) {
        this.iconDownTarget.classList.remove('hidden')
      }

      if (this.hasIconUpTarget) {
        this.iconUpTarget.classList.add('hidden')
      }
    }
  }
}
