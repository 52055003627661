import { Controller } from 'stimulus'
import Rails from '@rails/ujs'

export default class extends Controller {
  static targets = [
    'cardElement',
    'cardErrors',
    'createButton',
    'form',
    'stripePublishableKey'
  ]

  connect () {
    const stripe = Stripe(this.stripePublishableKeyTarget.value)
    const elements = stripe.elements()
    const card = elements.create('card')

    card.mount(this.cardElementTarget)

    var self = this
    card.addEventListener('change', function (event) {
      if (event.error) {
        self.cardErrorsTarget.innerHTML = event.error.message
      } else {
        self.cardErrorsTarget.innerHTML = ''
      }
    })

    this.createButtonTarget.addEventListener('click', function (event) {
      self.createButtonTarget.setAttribute('disabled', true)
      self.createButtonTarget.innerHTML = "Processing Payment..."
      stripe.createToken(card).then(function (result) {
        if (result.error) {
          self.createButtonTarget.removeAttribute('disabled')
          self.createButtonTarget.innerHTML = "Create Invoice"
          self.cardErrorsTarget.innerHTML = result.error.message
        } else {
          self.stripeTokenHandler(result.token)
        }
      })
    })
  }

  stripeTokenHandler (token) {
    const hiddenInput = document.createElement('input')
    hiddenInput.setAttribute('type', 'hidden')
    hiddenInput.setAttribute('name', 'invoice[payment][stripe_token]')
    hiddenInput.setAttribute('value', token.id)
    this.formTarget.appendChild(hiddenInput)

    Rails.fire(this.formTarget, 'submit')
  }
}
