import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'selectAll',
    'checkbox',
    'selectedValues',
    'count',
    'showWhenAnyChecked',
    'hideWhenAnyChecked'
  ]

  toggleSelectOne (e) {
    if (this.hasSelectedValuesTarget) {
      this.updateSelectedValues(e.currentTarget)
    }

    if (this.hasCountTarget) {
      this.updateCount()
    }

    if (this.hasSelectAllTarget)
      this.selectAllTarget.checked = this.checkboxTargets.every(checkbox => {
        return checkbox.checked
      })

    this.toggleVisibility()
    this.applySelectedStyles(e.currentTarget)
  }

  applySelectedStyles (checkbox) {
    if (!checkbox.closest('.checkbox-container')) return
    if (!this.data.has('selectedStyles')) return

    if (checkbox.checked) {
      checkbox
        .closest('.checkbox-container')
        .classList.add(...this.data.get('selectedStyles').split(' '))
    } else {
      checkbox
        .closest('.checkbox-container')
        .classList.remove(...this.data.get('selectedStyles').split(' '))
    }
  }

  toggleSelectAll (e) {
    for (let checkbox of this.checkboxTargets) {
      if (!checkbox.disabled) {
        checkbox.checked = this.selectAllTarget.checked
      }

      this.applySelectedStyles(checkbox)
    }

    if (this.hasHiddenWhenAnyCheckedTargets) {
      if (this.checkboxTargets.some(checkbox => checkbox.checked)) {
        for (const hiddenTarget of this.hiddenWhenAnyCheckedTargets) {
          hiddenTarget.classList.add('hidden')
        }
      }
    }

    if (this.hasSelectedValuesTarget) {
      this.setSelectedValues()
    }

    if (this.hasCountTarget) {
      this.updateCount()
    }

    this.toggleVisibility()
  }

  updateCount () {
    if (
      this.checkboxTargets.some(checkbox => {
        return checkbox.checked
      })
    ) {
      this.countTarget.innerHTML = this.checkboxTargets.filter(checkbox => {
        return checkbox.checked
      }).length
      this.countTarget.classList.remove('hidden')
    } else {
      this.countTarget.innerHTML = ''
      this.countTarget.classList.add('hidden')
    }
  }

  setSelectedValues () {
    let values = []

    if (this.selectAllTarget.checked) {
      values = this.checkboxTargets.map(checkbox => {
        return checkbox.dataset.id
      })
    }

    this.selectedValuesTarget.value = values.join(',')
  }

  updateSelectedValues (checkbox) {
    let values = []

    if (this.selectedValuesTarget.value) {
      values = this.selectedValuesTarget.value.split(',')
    }

    if (checkbox.checked) {
      values.push(checkbox.dataset.id)
    } else {
      values = values.filter(value => {
        return value != checkbox.dataset.id
      })
    }

    this.selectedValuesTarget.value = values.join(',')
  }

  toggleVisibility () {
    if (this.hasShowWhenAnyCheckedTarget) {
      if (this.checkboxTargets.some(c => c.checked)) {
        for (const target of this.showWhenAnyCheckedTargets) {
          target.classList.remove('hidden')
        }
      } else {
        for (const target of this.showWhenAnyCheckedTargets) {
          target.classList.add('hidden')
        }
      }
    }

    if (this.hasHideWhenAnyCheckedTarget) {
      if (this.checkboxTargets.some(c => c.checked)) {
        for (const target of this.hideWhenAnyCheckedTargets) {
          target.classList.add('hidden')
        }
      } else {
        for (const target of this.hideWhenAnyCheckedTargets) {
          target.classList.remove('hidden')
        }
      }
    }
  }
}
