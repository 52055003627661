import { Controller } from 'stimulus'
import Choices from 'choices.js'

export default class extends Controller {
  static targets = ['list']

  connect () {
    const choices = new Choices(this.listTarget, {
      classNames: {
        containerOuter: 'choices w-full',
        containerInner: 'choices__inner h-10 min-h-0 p-0 p-1'
      },
      searchResultLimit: 20
    })

    if (this.data.get('ajaxLoad') == 'true') {
      this.wireUpLoad(choices)
    }

    if (this.data.get('ajaxSearch') == 'true') {
      this.wireUpSearch(choices)
    }

    if (this.listTarget.classList.contains('error')) {
      this.listTarget.closest('.choices__inner').classList.add('bg-red-200')
    }

    if (this.data.has("autoSubmit")) {
      this.listTarget.addEventListener("change",() => { this.submitForm() })
    }
  }

  submitForm() {
    this.element.closest("form").submit();
  }

  wireUpLoad (choices) {
    fetch(`${this.data.get('ajaxLoadUrl')}`, {
      headers: {
        Accept: 'application/json'
      }
    })
      .then(response => response.json())
      .then(json => {
        choices.setChoices(json, 'id', this.data.get('ajaxLoadLabel'), true)
      })
      .catch(error => {
        console.error(error)
      })
  }

  wireUpSearch (choices) {
    this.element.addEventListener('search', (search, resultCount) => {
      if (search.detail.value.length >= 3) {
        fetch(
          `${this.data.get('ajaxSearchUrl')}?query=${search.detail.value}`,
          {
            headers: {
              Accept: 'application/json'
            }
          }
        )
          .then(response => response.json())
          .then(json => {
            choices.setChoices(
              json,
              'id',
              this.data.get('ajaxSearchLabel'),
              true
            )
          })
          .catch(error => {
            console.error(error)
          })
      }
    })
  }
}
