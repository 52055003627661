import { Controller } from 'stimulus'
import * as Accounting from 'accounting-js'
import axios from 'axios'

export default class extends Controller {
  static targets = [
    'choices',
    'propertyId',
    'detailsContainer',
    'details',
    'loadingItemables',
    'pricePerPerson',
    'sleeps',
    'total',
    'calculatingPricePerPerson'
  ]

  connect () {
    if (this.hasChoicesTarget) {
      this.choicesTarget.addEventListener('choice', choice => {
        this.propertyIdTarget.value = choice.detail.choice.value
        this.detailsContainerTarget.classList.remove('hidden')
        this.showPropertyItemables(choice.detail.choice.value)
        this.calculatePricePerPerson(choice.detail.choice.value)
      })
    }
  }

  showPropertyItemables (property_id) {
    this.loadingItemablesTarget.classList.remove('hidden')

    axios
      .get(`/properties/${property_id}/itemables`, {
        headers: {
          Accept: 'application/json'
        }
      })
      .then(response => response.data)
      .then(items => {
        this.detailsTarget.innerHTML = this.itemsTemplate(items)
      })
      .catch(error => {
        console.error(error)
      })
      .then(() => {
        this.loadingItemablesTarget.classList.add('hidden')
      })
  }

  autoPricePerPerson () {
    this.calculatePricePerPerson(this.propertyIdTarget.value, false)
  }

  overridePricePerPerson () {
    this.calculatePricePerPerson(this.propertyIdTarget.value, true)
  }

  calculatePricePerPerson (property_id, override = false) {
    this.calculatingPricePerPersonTarget.classList.remove('hidden')

    axios
      .get(`/properties/${property_id}/prices`, {
        headers: {
          Accept: 'application/json'
        },
        params: {
          price_per_person: this.pricePerPersonTarget.value,
          override: override
        }
      })
      .then(response => response.data)
      .then(json => {
        this.sleepsTarget.innerHTML = json.sleeps
        this.pricePerPersonTarget.value = json.price_per_person
        this.totalTarget.innerHTML = Accounting.formatMoney(json.total)
      })
      .catch(error => {
        console.error(error)
      })
      .then(() => {
        this.calculatingPricePerPersonTarget.classList.add('hidden')
      })
  }

  itemsTemplate (items) {
    return `<ul>${items.map(item => this.itemTemplate(item)).join('')}</ul>`
  }

  itemTemplate (item) {
    return `
      <li class="flex">
        <div class="w-12 mr-2 font-mono text-right">(${item.quantity})</div>
        <div>${item.name}</div>
      </li>
    `
  }
}
