import Flatpickr from 'stimulus-flatpickr'

export default class extends Flatpickr {
  change (selectedDates, dateStr, instance) {
    const dateDisplay = instance.formatDate(selectedDates[0], 'm/d/Y')
    const dateValue = instance.formatDate(selectedDates[0], 'Y-m-d')

    if (
      selectedDates[0].toLocaleDateString() === new Date().toLocaleDateString()
    ) {
      this.element.closest('form').querySelector('.date-display').innerHTML =
        'Today'
    } else {
      this.element
        .closest('form')
        .querySelector('.date-display').innerHTML = dateDisplay
    }

    this.element.closest('form').querySelector('.date-value').value = dateValue
  }
}
