import { Controller } from 'stimulus'
import * as Turbolinks from 'turbolinks'

export default class extends Controller {
  static targets = ['cancel']

  redirectToCancelUrl (e) {
    e.preventDefault()
    if (!this.data.has('cancelUrl')) return

    Turbolinks.visit(this.data.get('cancelUrl'))
  }

  cancel (e) {
    e.preventDefault()

    if (!this.hasCancelTarget) return

    const url = this.cancelTarget.dataset.url

    if (url) {
      Turbolinks.visit(url)
    }
  }
}
