import { Controller } from 'stimulus'
import * as Turbolinks from 'turbolinks'

export default class extends Controller {
  connect () {
    this.url = ''
    this.keyListen = false
  }

  process (e) {
    console.debug(e)

    if (e.key === 'F15') {
      this.keyListen = true
    } else if (e.key === 'Enter' && this.keyListen) {
      this.keyListen = false
      Turbolinks.visit(this.url)
    } else if (this.keyListen) {
      this.url += e.key
    }
  }
}
