import { Controller } from 'stimulus'
import Choices from 'choices.js'

export default class extends Controller {
  static targets = ['textbox']

  connect () {
    const choices = new Choices(this.textboxTarget, {
      classNames: {
        containerOuter: 'choices w-full',
        containerInner: 'choices__inner h-10 min-h-0 p-0 p-1'
      },
      searchResultLimit: 20,
      items: this.textboxTarget.dataset.aliases
        .split(',')
        .filter(alias => alias.length > 0),
      removeItemButton: true
    })
  }
}
