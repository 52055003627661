import Flatpickr from 'stimulus-flatpickr'

export default class extends Flatpickr {
  initialize () {
    this.config = {
      altInput: true,
      altFormat: 'm/d/Y',
      dateFormat: 'Y-m-d'
    }
  }
}
