import { Controller } from 'stimulus'
import * as Accounting from 'accounting-js'
import axios from 'axios'

export default class extends Controller {
  static targets = [
    'choices',
    'itemableId',
    'itemableType',
    'startDate',
    'endDate',
    'daysHuman',
    'quantity',
    'unitPrice',
    'taxRateContainer',
    'taxRateField',
    'taxRate',
    'taxExempt',
    'totalContainer',
    'total',
    'autoSubtotal',
    'overriddenSubtotal',
    'subtotalContainer',
    'tax',
    'stepTwo',
    'stepThree',
    'stepFour',
    'stepFive',
    'stepSix',
    'loading',
    'lowInventoryWarning',
    'noInventoryWarning',
    'kitInventoryWarning'
  ]

  connect () {
    this.unitPriceOverridden = false

    if (this.hasChoicesTarget) {
      this.choicesTarget.addEventListener('choice', choice => {
        this.itemableId = choice.detail.choice.value
        this.itemableType = choice.detail.choice.customProperties.type
        this.calculateTotal()
      })
    } else {
      this.itemableId = this.itemableIdTarget.value
      this.itemableType = this.itemableTypeTarget.value
      this.calculateTotal()
    }
  }

  calculateTotalAfterDatesChanged () {
    this.unitPriceOverridden = false
    this.calculateTotal()
  }

  calculateCustomTotal () {
    this.unitPriceOverridden = true
    this.calculateTotal()
  }

  calculateTotal () {
    this.checkForLowInventory()
    this.loadingTarget.classList.remove('hidden')

    axios
      .get('/rental_item_rates', {
        headers: {
          Accept: 'application/json'
        },
        params: {
          itemable_id: this.itemableId,
          itemable_type: this.itemableType,
          start_date: this.startDateTarget.value,
          end_date: this.endDateTarget.value,
          quantity: this.quantityTarget.value,
          unit_price: this.unitPriceTarget.value,
          tax_exempt: this.taxExemptTarget.checked,
          unit_price_overridden: this.unitPriceOverridden
        }
      })
      .then(response => response.data)
      .then(json => {
        this.stepTwoTarget.classList.remove('hidden')
        this.stepThreeTarget.classList.remove('hidden')
        this.stepFourTarget.classList.remove('hidden')
        this.stepFiveTarget.classList.remove('hidden')
        this.stepSixTarget.classList.remove('hidden')

        this.daysHumanTargets.forEach(t => (t.innerHTML = json.days_human))
        this.unitPriceTarget.value = json.unit_price
        this.totalContainerTarget.classList.remove('hidden')
        this.totalTarget.innerHTML = Accounting.formatMoney(json.total)

        if (json.tax_exempt) {
          this.taxRateContainerTarget.classList.remove('text-blue-500')
          this.taxRateContainerTarget.classList.add(
            'line-through',
            'text-gray-300'
          )
        } else {
          this.taxRateContainerTarget.classList.remove(
            'text-gray-300',
            'line-through'
          )
          this.taxRateContainerTarget.classList.add('text-blue-500')
        }

        this.taxRateFieldTarget.value = json.tax_rate
        this.taxRateTarget.innerHTML = json.tax_rate
        this.taxTarget.innerHTML = Accounting.formatMoney(json.tax)
        this.autoSubtotalTarget.innerHTML = Accounting.formatMoney(
          json.auto_subtotal
        )

        if (json.auto_subtotal !== json.actual_subtotal) {
          this.autoSubtotalTarget.classList.add('line-through', 'text-blue-200')
          this.overriddenSubtotalTarget.classList.remove('hidden')
          this.overriddenSubtotalTarget.innerHTML = Accounting.formatMoney(
            json.actual_subtotal
          )
        } else {
          this.autoSubtotalTarget.classList.remove(
            'line-through',
            'text-blue-200'
          )
          this.overriddenSubtotalTarget.classList.add('hidden')
        }
      })
      .catch(error => {
        console.error(error)
      })
      .then(() => {
        this.loadingTarget.classList.add('hidden')
      })
  }

  checkForLowInventory () {
    console.log("Checking for low inventory...")

    axios
      .get('/low_inventory_warnings', {
        headers: {
          Accept: 'application/json'
        },
        params: {
          itemable_id: this.itemableId,
          itemable_type: this.itemableType,
          start_date: this.startDateTarget.value,
          end_date: this.endDateTarget.value,
          quantity: this.quantityTarget.value
        }
      })
      .then(response => response.data)
      .then(json => {
        if(json.low_inventory && json.remaining_inventory < 0) {
          this.noInventoryWarningTarget.classList.remove('hidden')
          this.lowInventoryWarningTarget.classList.add('hidden')
          this.kitInventoryWarningTarget.classList.add('hidden')
        }
        else if(json.low_inventory) {
          this.lowInventoryWarningTarget.classList.remove('hidden')
          this.noInventoryWarningTarget.classList.add('hidden')
          this.kitInventoryWarningTarget.classList.add('hidden')
        }
        else if(json.length) {
          console.log(json)
          let message = `<p>The following items in this kit have low or no inventory for this time period:</p>
            <ul style="list-style: circle; padding-left: 2rem">`
          let lowInventories = json.filter((itemInventory) => itemInventory.low_inventory )
          console.log(lowInventories)
          lowInventories.forEach((itemInventory) => {
            console.log(itemInventory)
            message += `<li>${itemInventory.item_name}</li>`
          })
          message += "</ul>"

          this.kitInventoryWarningTarget.innerHTML = message
          if(lowInventories.length) {
            this.kitInventoryWarningTarget.classList.remove('hidden')
            this.lowInventoryWarningTarget.classList.add('hidden')
            this.noInventoryWarningTarget.classList.add('hidden')
          }
          else {
            this.kitInventoryWarningTarget.classList.add('hidden')
            this.lowInventoryWarningTarget.classList.add('hidden')
            this.noInventoryWarningTarget.classList.add('hidden')
          }
        }
        else {
          this.kitInventoryWarningTarget.classList.add('hidden')
          this.lowInventoryWarningTarget.classList.add('hidden')
          this.noInventoryWarningTarget.classList.add('hidden')
        }
      })
  }

  highlightPriceField () {
    this.unitPriceTarget.select()
  }

  autoUnitPrice () {
    this.unitPriceOverridden = false
    this.calculateTotal()
  }
}
