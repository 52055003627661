import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "client", "addNewInvoice" ]

  updateLink() {
    this.addNewInvoiceTarget.setAttribute(
      "href", `/invoices/new?client_id=${this.clientTarget.value}`)
  }
}
