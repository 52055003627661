import { Controller } from 'stimulus'
import * as Accounting from 'accounting-js'
import axios from 'axios'

export default class extends Controller {
  static targets = ['itemableType', 'rates', 'dailyRate', 'weeklyRate']

  connect () {
    this.element.addEventListener('choice', choice => {
      axios
        .get(`/itemables/${choice.detail.choice.value}`, {
          headers: {
            Accept: 'application/json'
          },
          params: {
            itemable_type: choice.detail.choice.customProperties.type
          }
        })
        .then(response => response.data)
        .then(json => {
          this.itemableTypeTarget.value = json.type

          this.dailyRateTarget.innerHTML = Accounting.formatMoney(
            json.daily_rate_cents / 100
          )

          this.weeklyRateTarget.innerHTML = Accounting.formatMoney(
            json.weekly_rate_cents / 100
          )

          this.ratesTarget.classList.remove('hidden')
        })
        .catch(error => {
          console.error(error)
        })
    })
  }
}
