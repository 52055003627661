import { Controller } from 'stimulus'
import dragula from 'dragula'
import Rails from '@rails/ujs'

export default class extends Controller {
  connect () {
    const fieldId = this.data.get('fieldId')

    let drake = dragula([this.element])
    drake.on('drop', (el, target, source, sibling) => {
      let form = el.querySelector('form')
      let currentPosition = parseInt(form.querySelector(fieldId).value)
      let newPosition = 'bottom'

      if (sibling) {
        let siblingPosition = parseInt(sibling.querySelector(fieldId).value)

        if (siblingPosition > currentPosition) {
          newPosition = siblingPosition - 1
        } else {
          newPosition = siblingPosition
        }
      }

      if (newPosition <= 0) {
        newPosition = 'top'
      }

      form.querySelector(fieldId).value = newPosition
      Rails.fire(form, 'submit')

      let scrollPosition = [window.scrollX, window.scrollY]

      document.addEventListener('turbolinks:load', function () {
        if (scrollPosition) {
          window.scrollTo.apply(window, scrollPosition)
          scrollPosition = null
        }
      })
    })
  }
}
